@use '../abstracts/mixins' as *;

.burger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.75rem 0;
    width: 1.5rem;
    cursor: pointer;
    pointer-events: all;

    @include devices(tablet){
        display: none;
    }
    
    span {
        position: absolute;
        width: 1.5rem;
        height: 1px;
        background-color: var(--primary);
        transition: all 0.3s ease;
    }

    &--active {
        span:nth-child(1) {
            transform: rotate(45deg);
        }
        span:nth-child(2) {
            transform: rotate(-45deg);
        }
    }
}