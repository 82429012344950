@use '../abstracts/mixins' as *;

#contact {
    border-top: 1px solid var(--primary);
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .section-title {
            margin: 0 0 2em 0;
        }

        .row {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: var(--padding) 0;

            @include devices(tablet) {
                flex-direction: row;

                .col:nth-child(1) {
                    padding-right: 2em;
                }
    
                .col:nth-child(2) {
                    padding-left: 2em;
                }
            }

            .col {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    font-family: 'Montserrat-Light', sans-serif;
                    font-size: 1rem;
                    text-transform: uppercase;
                    margin: 0.5em 0;
                }

                a {
                    font-family: 'Maung', serif;
                    font-size: clamp(1.5rem, 1.25rem + 1.25vw, 2.5rem);
                    text-decoration: none;
                    color: var(--primary);
                    margin: 0.5em 0;
                }
            }

            
        }
    }
}