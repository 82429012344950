@use '../abstracts/mixins' as *;

#karriere {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
            padding-top: var(--padding)!important;
            .row {
                display: grid;
                grid-template-columns: repeat(14, 1fr);

                .col {
                    padding: 0 1em;
                }
    
                .col:nth-child(1) {
                    grid-column: 1 / 2;
                    text-align: center;
                    font-size: 0.5rem;
                    min-width: 65px;
                }
    
                .col:nth-child(2) {
                    grid-column: 2 / 15;
                    border-left: 1px solid var(--primary);
                    padding-bottom: 1.5em;
                }

                @include devices(mobile) {
                    .col {
                        padding: 0 2em;
                    }
        
                    .col:nth-child(1) {
                        grid-column: 1 / 8;
                        font-size: 1rem;
                        text-align: end;
                    }
        
                    .col:nth-child(2) {
                        grid-column: 8 / 15;
                        border-left: 1px solid var(--primary);
                    }
                }
            }
        }
    }
}