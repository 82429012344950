/**
 * Basic typography style for copy text
 */

@use '../abstracts/mixins' as *;

body {
  font-family: 'Montserrat-Light', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

p {
  font-size: 1rem;
  line-height: 1.75;
}