// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Maung';
  font-display: swap;
  src: url('../../assets/fonts/Maung.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'Montserrat-Light';
  font-display: swap;
  src: url('../../assets/fonts/Montserrat-Light.ttf') format('truetype'), /* Modern Browsers */
}

@font-face {
  font-family: 'Montserrat-LightItalic';
  font-display: swap;
  src: url('../../assets/fonts/Montserrat-LightItalic.ttf') format('truetype'), /* Modern Browsers */
}