@use '../abstracts/mixins' as *;

#portfolio {
    .fluid-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .row {
            display: grid;
            grid-template-columns: var(--padding) repeat(12, 1fr) var(--padding);
            row-gap: var(--padding);
            padding: var(--padding) 0;

            @include devices(tablet){
                gap: var(--padding);
            }

            .col {
                .project-line {
                    border-bottom: 1px solid var(--primary);
                }
               .project-category {
                    font-family: 'Montserrat-Light';
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    @include letter-spacing(100);
                    margin: 1em 0 1em 0;
                  }
                  .project-title {
                    font-family: 'Maung', serif;
                    font-size: 2.5rem;
                    margin: 0.5em 0;
                  }

                  .project-description {
                    margin-bottom: 2em;
                  }

                  .project-link {
                    pointer-events: all;
                    text-decoration: none;
                    color: inherit;
                    border: 1px solid #000;
                    padding: 0.2em 1.5em;

                    &:hover {
                        background-color: #00000010;
                    }
                  }
            }

            .col:nth-child(2) {
                display: grid;
                grid-template-columns: var(--padding) 1fr var(--padding);
            
                .project-category {
                    grid-column: 1/4;
                }

                .content {
                    grid-column: 1 / 3;
                }
            }
        }

        .project-1 {
            .col:nth-child(1) {
                grid-column: 2/14;
            }

            .col:nth-child(2) {
                grid-column: 2/15;
                .project-line {
                    grid-column: 1/4;
                }
            }

            @include devices(tablet){
                .col:nth-child(1) {
                    grid-column: 2/10;
                }
    
                .col:nth-child(2) {
                    grid-column: 10/15;
                    .project-line {
                        grid-column: 1/4;
                    }
                }
            }
        }

        .project-2 {
            .col:nth-child(1) {
                grid-column: 2/14;
                grid-row: 1;
            }

            .col:nth-child(2) {
                grid-column: 1/15;
                
                .project-line {
                    grid-column: 2/4;
                }
                .content {
                    grid-column: 2 / 3;
                }
            }

            @include devices(tablet){
                .col:nth-child(1) {
                    grid-column: 6/14;
                    grid-row: 1;
                }
    
                .col:nth-child(2) {
                    grid-column: 1/6;
                    
                    .project-line {
                        grid-column: 1/4;
                    }
                    .content {
                        grid-column: 2 / 4;
                    }
                }
            }
        }

        .project-3 {
            .col:nth-child(1) {
                grid-column: 2/14;
            }

            .col:nth-child(2) {
                grid-column: 1/15;
                .project-line {
                    grid-column: 2/4;
                }
                .content {
                    grid-column: 2 / 3;
                }
            }

            @include devices(tablet){
                .col:nth-child(1) {
                    grid-column: 4/12;
                }
    
                .col:nth-child(2) {
                    grid-column: 4/15;
                    .project-line {
                        grid-column: 1/4;
                    }
                    .content {
                        grid-column: 1 / 3;
                    }
                }
            }
        }


        .project-4 {
            .col:nth-child(1) {
                grid-column: 2/14;
            }

            .col:nth-child(2) {
                grid-column: 2/15;
                .project-line {
                    grid-column: 1/4;
                }
            }

            @include devices(tablet){
                .col:nth-child(1) {
                    grid-column: 2/10;
                }
    
                .col:nth-child(2) {
                    grid-column: 10/15;
                    .project-line {
                        grid-column: 1/4;
                    }
                }
            }
        }
    }
}