@use '../abstracts/mixins' as *;

.preloader {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    width: 100%;
    max-width: 100%;
    z-index: 100;
    @include min-vh(100);
    background-color: #f6d0d5;
}
  
  .loader {
    width: 100%;
    height: 1px;
    position: absolute;
    overflow: hidden;
    background-color: #000;
    margin: 100px auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loader:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 1px;
    width: 40%;
    background-color: #f6d0d5;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.preloader--hide {
    opacity: 0;
  }

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}