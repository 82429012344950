#error {
    margin: 7.5em 0;
    .container {
        h1 {
            span {
                font-family: 'Maung', serif;
                font-size: 4rem;
                
            }
            font-family: 'Montserrat-Light', sans-serif;
            font-size: 1.5rem;
            margin: 0;
        }
    }
}