// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

.site-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

section, footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

// Container
.container {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);
}

// Fluid Container
.container-fluid {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.section-title {
  font-size: 1rem;
  @include letter-spacing(150);
  margin: 2em 0;
  text-transform: uppercase;
}

a {
  pointer-events: all;
}

.router-container {
  position: absolute;
  width: 100%;
}