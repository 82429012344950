@use '../abstracts/mixins' as *;

.project-page {
    padding-top: 7.5em;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .project-category {
            font-size: 1rem;
            @include letter-spacing(150);
            margin: 2em 0;
            text-transform: uppercase;
        }
    
        .project-title {
            font-family: 'Maung', serif;
            font-size: clamp(2.75rem, 2rem + 3.75vw, 5.75rem);
            margin: 0;
        }
    
        .project-description {
            width: 100%;

            @include devices(mobile) {
                max-width: var(--max-paragraph);
            }
        }

        .back-link {
            font-family: 'Montserrat-Light', sans-serif;
            font-size: 1rem;
            text-transform: uppercase;
            text-decoration: none;
            color: var(--primary);
            margin: 2em 0;
        }
    }
}

.project-impressions {
    img {
        margin: var(--padding) 0;
    }
}