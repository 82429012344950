// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.site-footer {
    border-top: 1px solid var(--primary);
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: calc(var(--padding) / 2) 0;

        nav {
            ul {
                display: flex;
                justify-content: space-between;
                gap: 2em;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    a {
                        font-family: 'Montserrat-Light', sans-serif;
                        font-size: 0.75rem;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: var(--primary);
                    }
                }
            }
        }
    }
}
