#faq {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .faq-title {
            font-family: 'Maung', serif;
            font-size: clamp(2.75rem, 2rem + 3.75vw, 5.75rem);
            margin: 0.1em 0;
            text-align: center;
            line-height: 1;
        }

        .row {
            .question {
                font-family: 'Montserrat-LightItalic';
                font-size: 0.75rem;
                max-width: var(--max-paragraph);
                text-align: center;
            }
            .answer {
                font-family: 'Montserrat-Light';
                font-size: 1rem;
                max-width: var(--max-paragraph);
                text-align: center;
            }
        }

        .row+.row {
            margin-top: 2em;
        }
    }
}