@use '../abstracts/mixins' as *;

#skills {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            max-width: 100%;

            @include devices(mobile) {
                max-width: var(--max-paragraph);
            }
        }
    }
}