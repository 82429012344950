// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------l
@use '../abstracts/mixins' as *;

.site-header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    
    backdrop-filter: blur(10px);
    z-index: 100;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: calc(var(--padding) / 4) var(--padding);
        border-bottom: 1px solid var(--primary);

        .logo {
            width: 50px;
        }

        nav {
            .nav-links--main {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 1rem;
                width: 100%;
                list-style: none;
                padding: 0;
                margin: 0;

                display: none;
                @include devices(tablet) {
                    display: flex;
                }

                li {
                    a {
                        font-family: 'Montserrat-Light', sans-serif;
                        font-size: 0.75rem;
                        color: var(--primary);
                        text-decoration: none;
                        text-transform: uppercase;
                        @include letter-spacing(100);
                        position: relative;
                        transition: clip-path 275ms ease;
                        &:hover span::before, &:focus span::before {
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                        }

                        span {
                            position: relative;
                            display: inline-block;
                            color: var(--primary);
                            &::before {
                              position: absolute;
                              content: attr(data-content);
                              color: var(--primary);
                              text-decoration: underline;
                              text-decoration-color: var(--primary);
                              clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
                              transition: clip-path 275ms ease;
                            }
                        }
                    }
                }
            }
        }

            .overlay-nav {
                display: none;
                flex-basis: 100%;
                padding: var(--padding) 0;
                
                &--active {
                    display: block;

                    @include devices(tablet) {
                        display:none;
                    }
                }

                .nav-links--overlay {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    width: 100%;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        a {
                            font-family: 'Montserrat-Light', sans-serif;
                            font-size: 0.75rem;
                            color: var(--primary);
                            text-decoration: none;
                            text-transform: uppercase;
                            @include letter-spacing(100);
                            position: relative;
                            transition: clip-path 275ms ease;
                            &:hover span::before, &:focus span::before {
                                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                            }
    
                            span {
                                position: relative;
                                display: inline-block;
                                color: var(--primary);
                                &::before {
                                  position: absolute;
                                  content: attr(data-content);
                                  color: var(--primary);
                                  text-decoration: underline;
                                  text-decoration-color: var(--primary);
                                  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
                                  transition: clip-path 275ms ease;
                                }
                            }
                        }
                    }
                }
            }
    }
}
