// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

@use './mixins.scss' as *;

:root {
  --max-width: 1600px;
  --padding: 1em;
  --max-paragraph: 400px;

  --primary: #000;

  @include devices(tablet) {
    --padding: 2em;
  }

  @include devices(desktop) {
    --padding: 4em;
  }
}