@use '../abstracts/mixins' as *;

#about {
    margin-top: 7.5em;
    .container {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        gap: var(--padding);

        .row:nth-child(1) {
            position: relative;
            grid-column: 1 / 15;

            h2 {
                position: relative;
                font-family: 'Maung', serif;
                font-size: clamp(6rem, 2.5rem + 17.5vw, 20rem);
                margin: 0;
            }
        }

        .row:nth-child(2) {
            position: relative;
            z-index:-1;
            margin-top: 2em;
            grid-column: 1 / 15;
        }

        @include devices(mobile){
            .row:nth-child(1) {
                position: relative;
                grid-column: 1 / 8;
            }
    
            .row:nth-child(2) {
                margin-top: 2em;
                grid-column: 8 / 15;
            }
        }

        @include devices(desktop){
            .row:nth-child(1) {
                position: relative;
                grid-column: 3 / 8;
            }
    
            .row:nth-child(2) {
                margin-top: 2em;
                grid-column: 8 / 13;
            }
        }
    }
}